define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let config;

  if ((0, _runtime.macroCondition)((0, _runtime.isTesting)())) {
    config = (0, _runtime.config)("/Users/soonlam/Documents/adtiki-proj/ablife/node_modules/ember-get-config").testConfig;
  } else {
    config = (0, _runtime.config)("/Users/soonlam/Documents/adtiki-proj/ablife/node_modules/ember-get-config").config;
  }

  var _default = config;
  _exports.default = _default;
});